{
  "503": {
    "1": "申し訳ございませんが、ただいまページをご利用できない状態です。（503 エラー）",
    "2": "一定時間経過後、再度ページの更新をお試しください。"
  },
  "ActivateEmailLoginModal": {
    "1": "メールアドレスでのログインが有効になりました！",
    "2": "すでに登録されているメールアドレスです",
    "3": "ログイン用のパスワードをご設定ください。",
    "4": "{{email}}宛てに確認メールを送信いたします。",
    "5": "設定中...",
    "6": "設定",
    "7": "パスワードの設定"
  },
  "AnswerSelectBox": {
    "1": "選択肢",
    "2": "あとでもう一度チェックする",
    "3": "回答する",
    "4": "スキップ"
  },
  "CardForm": {
    "1": "カード番号",
    "2": "有効期限",
    "3": "セキュリティコード",
    "4": "申し訳ありません。こちらのカードブランドは対応しておりません。",
    "5": "カードの認証に失敗しました。カード番号等お確かめのうえ、再度お試しください。",
    "6": "クレジットカードを登録する",
    "7": "変更する"
  },
  "CertificationModal": {
    "1": "おめでとうございます！",
    "2": "{{curriculum}}を修了しました。",
    "3": "修了証明書",
    "4": "あなたが{{curriculum}}を修了したことをここに証明します。",
    "5": "{{userName}}さん"
  },
  "ChangePasswordModal": {
    "1": "パスワードを変更しました",
    "2": "現在のパスワードが違います",
    "3": "メールを送信しました。メール本文のリンクから、再設定を完了させてください。",
    "4": "このメールアドレスのアカウントは存在しません",
    "5": "認証に失敗しました。時間を空けて再度お試しください。",
    "6": "パスワードの再設定",
    "7": "下記メールアドレス宛にパスワード再設定のためのリンクを送信します。",
    "8": "送信中",
    "9": "送信",
    "10": "問題が発生しました。時間を空けて再度お試しください。"
  },
  "ChapterCard": {
    "1": "添削問題",
    "2": "チャプターを閉じる",
    "3": "チャプターを開く",
    "4": "添削問題の復習は",
    "5": "こちら",
    "6": "から",
    "7": "添削問題は",
    "8": "こちら",
    "9": "から",
    "10": "Google Colaboratoryの使い方は",
    "11": "添削問題は提出済みです",
    "12": "Google Colaboratory の使い方は",
    "13": "こちら",
    "14": "から",
    "15": "添削課題未提出",
    "16": "添削課題提出済み",
    "17": "このコースをはじめる",
    "18": "提出課題の提出方法は",
    "19": "こちら",
    "20": "からご確認ください",
    "21": "添削問題はこちら"
  },
  "ConfirmQuitTestModal": {
    "1": "テストを終了しますか？",
    "2": "キャンセル",
    "3": "終了する",
    "4": "リセット"
  },
  "ConsumerLogin": {
    "1": "不正または未入力の項目があります。",
    "2": "確認メールの送信に失敗しました。このメールアドレスでアカウントは登録されていません。",
    "3": "パスワード再設定",
    "4": "登録中のメールアドレスを入力してください。",
    "5": "パスワード再設定のためのリンクを送信します。",
    "6": "ようこそ！ログインしました。",
    "7": "お支払い",
    "8": "不正なメールアドレスです",
    "9": "このアカウントは現在使用できません",
    "10": "存在しないアカウントです。利用するには新規登録をしてください。",
    "11": "メールアドレスまたはパスワードが違います",
    "12": "このアカウントはプランに登録されていません。",
    "13": "ログイン",
    "14": "{{how}}でログイン",
    "15": "パスワードを忘れた",
    "16": "認証に失敗しました。時間を空けて再度お試しください。",
    "17": "メールを送信しました。メール本文のリンクから、再設定を完了させてください。",
    "18": "送信",
    "19": "サードパーティのCookieの使用を許可してください。",
    "20": "アカウントは存在しません。",
    "21": "時間を空けて再度お試しください。",
    "22": "認証に失敗しました。",
    "23": "ログイン",
    "24": "または",
    "25": "ログイン中...",
    "26": "ログインの試行回数が上限に達しました。しばらくしてからもう一度お試しください。"
  },
  "CourseCard": {
    "1": "テスト出題不可",
    "2": "続きから復習する",
    "3": "まだコースに取り組んでいません",
    "4": "チケット",
    "5": "枚",
    "7": "続きからはじめる",
    "8": "コース詳細へ",
    "9": "はじめる",
    "10": "受講ライセンスが",
    "11": "チケット",
    "12": "再開する",
    "13": "復習する",
    "14": "無料ではじめる",
    "15": "必要です"
  },
  "CourseDetailPageRightColumn": {
    "1": "予習必須！",
    "2": "できればやろう",
    "3": "理解が深まるおすすめのコース",
    "4": "チケット引き換えコースを購入するにはログイン新規登録が必要です。",
    "5": "チケット{{requiredNumOfTickets}}枚で引き換える",
    "6": "クーポンをお持ちの方はこちら",
    "8": "このコースで使用しているデータセット",
    "9": "ダウンロードする",
    "10": "このコースを評価する",
    "11": "アンケートへのご協力をお願いしております。",
    "12": "評価する",
    "13": "このコースを理解するために",
    "14": "このコースを改善していくために、アンケー",
    "15": "コードをリセット",
    "16": "受講済み",
    "17": "次に受講必須コース",
    "18": "トへのご協力をお願いしております。"
  },
  "CourseQuestionnaireModal": {
    "1": "期待していた内容は学習できましたか？",
    "3": "教材は分かりやすいと感じましたか？",
    "5": "学習内容の活用シーンをイメージできましたか？",
    "6": "「{{name}}」について",
    "7": "教材の品質向上を目的として、アンケートへのご協力をお願いしております。",
    "8": "なお、お急ぎの場合は右上の「✕」よりスキップいただけます。",
    "9": "送信する",
    "10": "選択されていません",
    "11": "全くできなかった",
    "12": "できなかった",
    "13": "普通だった",
    "14": "できた",
    "15": "期待以上にできた",
    "16": "選択されていません",
    "17": "とても分かりづらかった",
    "18": "分かりづらかった",
    "19": "普通だった",
    "20": "分かりやすかった",
    "21": "とても分かりやすかった",
    "22": "コメントがございましたら、こちらにご記入ください。",
    "23": "教材の品質向上を目的として、",
    "24": "このコースを理解するために",
    "25": "普通だった",
    "26": "できた",
    "27": "期待以上にできた"
  },
  "CourseQuestionnaireThanksModal": {
    "1": "ご協力ありがとうございました！",
    "2": "送信が完了しました！",
    "3": "頂いたご感想を元に、引き続き教材の品質向上に取り組んでまいります。",
    "5": "閉じる",
    "6": "引き続き、Aidemyのご利用をお願いいたします。"
  },
  "CourseSearchNav": {
    "1": "対象",
    "2": "主なコース形式",
    "3": "絞り込み検索",
    "4": "サブカテゴリ",
    "5": "フリーワード検索",
    "6": "検索条件を解除",
    "7": "検索する",
    "8": "フリーワード検索（例：Python）",
    "9": "（",
    "10": "件）",
    "11": "カテゴリ",
    "12": "全て",
    "13": "例：Python",
    "14": "クリア"
  },
  "CoursesList": {
    "1": "コースは全部で",
    "2": "種類。興味のあるコースを選択してみましょう。",
    "3": "お探しのコースが見つからない場合はページ上部の検索機能をご利用ください",
    "4": "すべて表示",
    "5": "学習コース一覧",
    "6": "コース一覧"
  },
  "Bookmark": {
    "1": "ブックマーク",
    "2": "コースやエクササイズのブックマークをご確認いただけます",
    "3": "絞り込み検索",
    "4": "コース詳細へ",
    "5": "未完了からはじめる",
    "6": "添削課題提出済",
    "7": "添削課題未提出",
    "8": "絞り込み検索",
    "9": "ブックマーク",
    "10": "追加日 {{date}}",
    "11": "新しい順",
    "12": "古い順",
    "13": "完了",
    "14": "受講必須",
    "15": "コース",
    "16": "エクササイズ",
    "17": "ブックマークに追加されたコースがありません",
    "18": "復習や後で見たいコースが見つかったら、",
    "19": "各コース詳細画面のブックマークボタンから追加しましょう",
    "20": "ブックマークに追加されたエクササイズがありません",
    "21": "復習や後で見たいエクササイズが見つかったら、",
    "22": "演習画面のブックマークボタンから追加しましょう",
    "23": "受講推奨"
  },
  "CoursesShow": {
    "1": "このコースをはじめから復習する",
    "2": "未完了のエクササイズからはじめる",
    "3": "このコースをはじめる",
    "4": "コースで過去に書いたコードを削除します。よろしいですか？",
    "5": "（各問題のクリア履歴は削除されません）",
    "6": "このコースで書いたコードを削除する",
    "7": "このコースの講師",
    "8": "コードが削除されました！",
    "9": "次に受講必須コース",
    "10": "学習したコードをリセットしてもよろしいですか？",
    "11": "受講状況や進捗はリセットされません",
    "12": "もっと見る",
    "13": "ブックマーク追加済み",
    "14": "ブックマークへ追加",
    "15": "リセット",
    "16": "コース詳細へ",
    "17": "閉じる"
  },
  "EmailSettingModal": {
    "1": "確認メールを送信しました。メールから、メールアドレスの認証をしてください。",
    "2": "パスワードが違います",
    "3": "ログイン用メールアドレスの変更",
    "4": "設定されたアドレス宛てに確認メールを送信いたします。",
    "5": "パスワードの確認",
    "6": "操作を続行するにはパスワードをご入力ください。",
    "7": "メールアドレスの設定・変更",
    "8": "すでに登録されているメールアドレスです",
    "9": "設定中...",
    "10": "設定",
    "11": "問題が発生しました。時間を空けて再度お試しください。"
  },
  "EvaluateButtonBlock": {
    "1": "高評価を取り消し",
    "2": "この演習を高く評価",
    "3": "低評価を取り消し",
    "4": "この演習を低く評価"
  },
  "ExamFinishedModal": {
    "1": "試験時間が終了しました。",
    "2": "テスト結果へ"
  },
  "ExamResult": {
    "1": "テスト解答（正誤確認ページ）",
    "2": "マイページへ戻る",
    "3": "マイページ",
    "4": "このテストは存在しません",
    "5": "問題が発生しました。時間を空けて再度お試しください。",
    "6": "試験情報",
    "7": "テスト解答（正誤確認ページ）が非公開に設定されているため閲覧できません"
  },
  "ExamShow": {
    "1": "受験期限切れ",
    "2": "再テストを受験する",
    "3": "テスト受験期限",
    "4": "受験前の確認事項",
    "5": "テストは開始後、中断できません。時間や環境に余裕を持って受験ください。",
    "6": "テスト受験に関するお問い合わせは、御社の管理者へお問い合わせください。",
    "7": "管理者より再テスト受験を求められています。",
    "8": "不合格",
    "9": "{{score}}点（{{year}}年{{month}}月{{day}}日受験）",
    "10": "この試験を受験することはできません。マイページに戻ります。",
    "11": "この試験は存在しません。マイページに戻ります。",
    "12": "別の試験を受験中です。マイページに戻ります。",
    "13": "試験情報が不正です。管理者にお問い合わせください。マイページに戻ります。",
    "14": "受講ライセンスが付与されていません。マイページに戻ります。",
    "15": "この試験はこのチームでは受験できません。マイページに戻ります。",
    "16": "問題が発生しました。マイページに戻ります。",
    "17": "再テストを再開する",
    "18": "マイページ",
    "19": "試験情報",
    "20": "問題が発生しました。時間を空けて再度お試しください。",
    "21": "テスト解答（正誤確認ページ）を見る",
    "22": "受験する",
    "23": "再開する",
    "24": "合格",
    "25": "YYYY年M月D日",
    "26": "制限時間",
    "27": "分",
    "28": "以下のボタンを押すと、直ちにテストが開始されます。",
    "29": "テスト解答（正誤確認ページ）が非公開に設定されているため閲覧できません",
    "30": "テスト受験終了後、即座に解答や結果を確認できるかは管理者により設定されています。",
    "31": "スクリーンショットやスマートフォンなどで、画面撮影することは禁止しております。"
  },
  "ExcludedFromExamModal": {
    "1": "テスト受験には受講ライセンスが必要です",
    "2": "管理者により、受講ライセンスが無効になりました。",
    "3": "不都合がございましたら、御社の管理者にお問い合わせください。",
    "4": "マイページへ移動する"
  },
  "Exercise": {
    "1": "回答中...",
    "2": "本当にリセットしますか",
    "3": "リセット",
    "4": "エクササイズタイトル",
    "5": "間違っています",
    "6": "通信に失敗しました。もう一度お試しください。",
    "7": "実行中です。この問題のコードの実行には最大{{number}}秒かかりますので、しばらくお待ち下さい。",
    "8": "実行に失敗しました。もう一度お試しください。",
    "9": "実行時間が長すぎます。コードを見直してからもう一度お試しください。",
    "10": "その file は実行できないです",
    "11": "ご意見はこちらから",
    "12": "入力を終わる",
    "13": "タブレット入力モード",
    "14": "回答する",
    "15": "正常に実行できませんでした。時間をあけてお試しください。",
    "16": "問題が発生しました。時間を空けて再度お試しください。",
    "17": "まずは実行してみよう！",
    "18": "実行エラーです。コードを修正して再度実行してください。"
  },
  "ExerciseBlockOutputImages": {
    "1": "出力画像"
  },
  "ExerciseBlockQuestion": {
    "1": "問題",
    "2": "もう一度、説明を見る",
    "3": "ヒントを見る",
    "4": "もう一度大きく表示する",
    "5": "答えを見る"
  },
  "ExerciseEditor": {
    "1": "コードを編集する",
    "2": "入力画面を閉じる"
  },
  "ExerciseModalDescription": {
    "1": "コース詳細へ戻る",
    "2": "演習画面へ",
    "3": "この説明の演習問題へ",
    "4": "完了にして次の説明へ"
  },
  "ExerciseNotification": {
    "1": "次へ進む",
    "2": "もう一度説明を見るか、困ったらヒントを見てみましょう",
    "3": "正解です！次へ進みましょう！",
    "4": "もう一度大きく表示する",
    "5": "実行エラーです。コードを修正して再度実行してください。",
    "6": "この問題にヒントはありません",
    "7": "次へ進みましょう！"
  },
  "Header": {
    "1": "マイページ",
    "2": "コース一覧",
    "3": "アップグレード",
    "4": "管理者画面",
    "5": "スマートフォンからの管理画面の操作はサポートしていません",
    "6": "チューター用チームページ",
    "7": "アカウント設定",
    "8": "はじめての方へ",
    "9": "サポート",
    "10": "ログアウト",
    "11": "質問する",
    "12": "{{days}}営業日以内返信",
    "13": "おすすめのカリキュラム",
    "14": " さん",
    "15": "お知らせ",
    "16": "受講マニュアル",
    "17": "ブックマーク"
  },
  "InitialAgreementModal": {
    "1": "個人情報の取扱への同意が完了しました",
    "2": "同意の設定に失敗しました。ページを再読み込みしてからお試しください",
    "3": "ご利用の前に（個人情報の取り扱いについて）",
    "4": "以下において、「個人情報」とは、貴殿のメールアドレス、氏名、及びその他アイデミーのサービスに登録し、又は同サービスを利用するためにご提供いただいた情報のうち、日本の個人情報保護に関する法規制で定義される個人情報に該当するものを指します。",
    "5": "アイデミーは、貴殿の個人情報をアイデミーの",
    "6": "プライバシーポリシー",
    "7": "に従い、取り扱います。",
    "8": "アイデミーは、以下（１）、（２）の場合に限り、貴殿の個人情報の第三者提供を行うことがあります。",
    "9": "（１）登録された貴殿のメールアドレスや氏名等に紐付く形で、アイデミーのサービス内に存在する貴殿の受講状況や成績等の情報（以下「受講状況」といいます）を貴殿のサービス利用に係る申込契約の当事者（貴殿の所属法人、その関係会社*1、又は、貴殿の内定法人等を含む貴殿に関係する第三者*2のうちのいずれかの当事者がこれに該当します。）に提供する場合",
    "10": "なお、同意はいつでも撤回することができます。撤回を希望される場合は、以下の窓口までご連絡ください。",
    "11": "株式会社アイデミー個人情報相談窓口",
    "12": "以上の内容について確認し、同意の上、アイデミーのサービスを利用します。",
    "13": "設定中...",
    "14": "同意して次へ",
    "15": "＊1 関係会社：関係会社への提供は、貴殿の所属法人とその関係会社がアイデミーのサービスを同一環境で共同利用をしている場合に限ります。",
    "16": "最終更新日：2022年11月11日",
    "17": "【ご注意】貴殿が日本以外の国からアイデミーのサービスを利用する場合には、アイデミーは貴殿の個人情報を一切お取扱いしません。",
    "18": "＊2 貴殿の内定法人等を含む貴殿に関係する第三者：貴殿とアイデミーのサービス利用申込契約当事者との関係性が、内定者とその内定法人、出向者と出向勤務先、業務受託者と業務委託者、派遣社員と派遣勤務先等である場合には、内定法人、出向勤務先、業務委託者及び、派遣勤務先等がこれに該当します。",
    "19": "（２）貴殿のサービス利用に係る申込契約の当事者が、貴殿の所属法人の関係会社である場合に、当該関係会社から、貴殿の所属法人（又は貴殿の内定法人を含む貴殿に関係する第三者）に対し受講状況を開示するよう委託を受け、貴殿の所属法人（又は貴殿の内定法人を含む貴殿に関係する第三者）に提供する場合",
    "20": "【ご注意】貴殿が日本以外の国からアイデミーのサービスを利用する場合には、チェックを入力いただいた場合であっても、アイデミーは貴殿の個人情報を一切お取扱いいたしません。このシステムの仕様上、チェックボックスにチェックを入力いただかないと先に進めないため、チェックの入力をお願いいたします。"
  },
  "InitialMailSettingModal": {
    "1": "メール受信設定が完了しました",
    "2": "メール受信設定に失敗しました。ページを再読み込みしてからお試しください",
    "3": "メール受信設定",
    "4": "受信を希望するメール通知をご確認ください。",
    "5": "Aidemyからコース・機能・受講ライセンス期限などのお知らせを受け取る",
    "6": "Aidemyからキャンペーン・セミナー・活用事例など広告宣伝の内容を含むお知らせを受け取る",
    "7": "設定中...",
    "8": "設定する",
    "9": "次へ",
    "10": "※上記のお知らせを",
    "11": "受け取らない方",
    "12": "はそれぞれ",
    "13": "チェックを外してください"
  },
  "InitialPasswordSettingModal": {
    "1": "パスワードの設定",
    "2": "パスワードを更新します。",
    "3": "設定中...",
    "4": "設定",
    "5": "ログインしました。",
    "6": "認証に失敗しました。時間を空けて再度お試しください。",
    "8": "パスワードは8文字以上で設定してください",
    "9": "パスワードは英数字のみで記入してください",
    "10": "新しいパスワード（英数字8文字以上）"
  },
  "Inputs": {
    "1": "パスワードは6文字以上で設定してください",
    "2": "適切なパスワードではありません",
    "3": "メールアドレスの形式が正しくありません"
  },
  "LastQuestionModal": {
    "1": "最後の問題です。見直しが完了したら、",
    "2": "画面上部「テストを終了する」を押してください。",
    "3": "はい",
    "4": "画面上部「終了する」を押してください。"
  },
  "Login": {
    "1": "パスワードを忘れた場合",
    "3": "招待情報がありません。管理者にお問い合わせください。",
    "4": "招待情報が正しくありません。管理者にお問い合わせください。",
    "5": "ログイン",
    "6": "メールアドレス",
    "7": "パスワード",
    "8": "ログイン中...",
    "9": "メールアドレスまたはパスワードが違います。",
    "10": "メールアドレスの形式が正しくありません。",
    "11": "入力されていない値があります。",
    "12": "ログインできない場合",
    "13": "urlPrefix"
  },
  "ManualMenu": {
    "1": "画面・機能で探す",
    "2": "マイページ",
    "3": "コース一覧・詳細",
    "4": "プログラム／動画演習",
    "5": "テスト受験・結果",
    "6": "アカウント設定"
  },
  "ManualMypage": {
    "1": "はじめての方へ",
    "2": "Aidemyをはじめて利用する方に向けて、各画面の基本操作をご紹介します"
  },
  "Modal": {
    "1": "登録しました。確認メールを送信しましたので、メールから、メールアドレスの認証をしてください。",
    "2": "5秒で終わるログイン / 新規登録",
    "3": "個人情報取り扱い",
    "4": "特定電子メール送付について",
    "5": "他のログイン方法はこちらから",
    "6": "パスワードを忘れた",
    "7": "不正または未入力の項目があります。",
    "8": "メールを送信しました。メール本文のリンクから、再設定を完了させてください。",
    "9": "確認メールの送信に失敗しました。このメールアドレスでアカウントは登録されていません。",
    "10": "認証に失敗しました。時間を空けて再度お試しください",
    "11": "登録中のメールアドレスを入力してください。",
    "12": "パスワード再設定のためのリンクを送信します。",
    "13": "パスワード再設定",
    "14": "送信",
    "15": "サードパーティのCookieの使用を許可してください。",
    "16": "アカウントは存在しません。",
    "17": "時間を空けて再度お試しください。",
    "18": "認証に失敗しました。",
    "19": "ようこそ！ログインしました。",
    "20": "上記に同意して新規登録する",
    "21": "5秒で無料会員登録",
    "22": "前回と同じ方法でログインする",
    "23": "新規登録",
    "24": "ログイン",
    "25": "または",
    "26": "利用規約",
    "27": "ログインはこちら",
    "28": "新規登録はこちら",
    "29": "ログイン中...",
    "30": "新規登録中...",
    "31": "初めてのご利用ですか？"
  },
  "MypageNew": {
    "1": "開く",
    "2": "閉じる",
    "3": "受講必須",
    "4": "受講推奨",
    "5": "完了",
    "6": "ピックアップニュース",
    "7": "β版",
    "8": "先端技術や社会に関する情報をアップデートしましょう",
    "9": "すべて見る",
    "10": "※記事をクリックすると、当社サービスから離れ、日経BPのWebサイトに移動します",
    "11": "ピックアップニュース記事をクリックすると、当社サービスから離れ、日経BPのWebサイトに移動します",
    "12": "まだ受講中のコースはありません",
    "13": "「未完了」タブから、学習をスタートしてみましょう",
    "14": "カリキュラム以外のコースも学んでみませんか？",
    "15": "アイデミーの有料プランで、さらに学習を進めてみませんか？",
    "16": "アイデミーには現在{{num}}のコースがあり、毎月新しいコースが追加されています。",
    "17": "まだ受講済のコースはありません",
    "18": "「未完了」または「受講中」タブから、学習を進めましょう",
    "19": "コース一覧へ",
    "20": "個人のお客様はこちら",
    "21": "法人のお客様はこちら",
    "22": "学習状況",
    "23": "カリキュラム進捗",
    "24": "完了コース数",
    "25": "残り受講日数",
    "26": "添削課題提出数",
    "27": "修了したカリキュラムはありません",
    "28": "引き続き学習を進めましょう",
    "29": "お知らせ",
    "30": "修了証",
    "31": "前回の続きからはじめる",
    "32": "コース詳細へ",
    "33": "テスト詳細へ",
    "34": "点",
    "35": "未完了",
    "36": "受講中",
    "37": "受講済",
    "38": "テスト",
    "39": "未完了からはじめる",
    "40": "添削課題提出済",
    "41": "添削課題未提出",
    "42": "あなたのカリキュラム",
    "43": "あなたにオススメのコース",
    "45": "受講任意のコース",
    "46": "学習コース",
    "48": "新着コース",
    "49": "Aidemy Freeで受講可能なコース",
    "50": "日",
    "51": "{{date}}まで",
    "52": "無制限",
    "53": "無期限",
    "54": "正確な期間は貴社運営事務局へお問合せください",
    "55": "ライセンス期限：",
    "56": "Aidemy Freeをご利用中です",
    "57": "ライセンスが割り当てられておりません",
    "58": "受験可能なテスト",
    "59": "現在受験可能なテストはありません",
    "60": "受験期限切れのテスト",
    "61": "期限切れのテストを受験するには、貴社運営事務局へお問い合わせください",
    "62": "受験済みのテスト",
    "63": "テスト詳細より、テスト結果（正誤確認）をご確認いただけます",
    "64": "受験済みのテストはありません",
    "65": "「受験可能なテスト」および「受験済みのテスト」がありません",
    "66": "貴社運営事務局にてテストが設定されると本機能をご利用いただけます",
    "67": "日",
    "68": "月",
    "69": "火",
    "70": "水",
    "71": "木",
    "72": "金",
    "73": "土",
    "74": "YYYY年M月D日（ddd）",
    "75": "合格",
    "76": "不合格",
    "77": "受験期限",
    "78": "受験完了",
    "79": "マイページ",
    "80": "テスト情報の取得に失敗しました",
    "81": "セキュリティ強化のため、パスワードの変更をお願いします",
    "82": "本サービスでは以上のコースを自由に選んでご受講可能です。",
    "83": "目的別のおすすめカリキュラムは こちらのページをご参照ください。",
    "84": "アイデミーが特に受講をおすすめするコースに「受講必須」マークがございます。「受講必須」対象コースの受講を完了せずとも、学習に支障はございません。",
    "85": "本プログラムにおける資格の受験資格発行基準は下記の通りです。",
    "86": "受講必須マークが付くコースを受講しカリキュラム進捗率が100％になること。",
    "87": "修了テストにて、60%以上（64問以上）正解すること。",
    "88": "上記を満たした場合に1週間以内を目処として受験資格を発行し、お申し込みの際にご登録のメールアドレス宛にご連絡いたします。",
    "89": "修了テストは下記リンクから受験いただけます。",
    "90": "受講者番号欄は「select」とご記入ください。",
    "91": "なお、E2022#2試験(※)に受験申込するの場合の修了期日は【8/7(日)】です。",
    "92": "(※)2022年8月26日(金)、27日(土)",
    "93": "もっと見る",
    "94": "Aidemy Gritをご利用中です",
    "95": "まずは「バーチャル学習室」へ",
    "96": "学習を始める際に開いておくと便利です！",
    "97": "学習中の課題をスピーディに解決しましょう",
    "98": "学習を始める際に開いておくと便利です！学習中の課題をスピーディに解決しましょう",
    "99": "制限時間",
    "100": "分",
    "101": "合否・得点"
  },
  "News": {
    "1": "出典",
    "2": "ニュース",
    "3": "β版",
    "4": "記事をクリックすると、当社サービスから離れ、日経BPのWebサイトに移動します"
  },
  "MypageSettings": {
    "1": "ファイルサイズを小さくしてお試しください",
    "2": "同じユニークIDを持ったメンバーが既に存在します",
    "3": "アカウントを削除しました。",
    "4": "問題が発生しました。アカウントの削除に失敗しました。時間を空けて再度お試しください。",
    "5": "jpeg、pngの画像のみに対応しております",
    "6": "50KB以上のファイルには対応しておりません",
    "7": "日本語",
    "8": "管理者によって変更が制限されている項目があります。",
    "9": "変更が必要な場合は御社の管理者へお問い合わせください。",
    "10": "プロフィール画像",
    "11": "画像を変更",
    "12": "チーム名",
    "13": "ユーザー名",
    "14": "更新中...",
    "15": "指定なし",
    "16": "アカウントを削除します。本当によろしいですか？（OKを選択されますと即時利用不可となり進捗記録等は削除されます）",
    "17": "退会を希望する",
    "18": "更新",
    "19": "アカウント設定",
    "20": "ユーザー名は50文字以内でご入力ください。",
    "21": "問題が発生しました。ページを再読み込みしてからお試しください",
    "22": "更新しました",
    "23": "Aidemyからコース・機能・受講ライセンス期限などのお知らせを受け取る",
    "24": "Aidemyからキャンペーン・セミナー・活用事例など広告宣伝の内容を含むお知らせを受け取る",
    "25": "アカウント設定",
    "26": "なし",
    "27": "パスワード",
    "28": "パスワードを変更",
    "29": "メールアドレス",
    "30": "メールアドレスの形式が正しくありません。",
    "31": "ユニークID",
    "32": "ユニークIDは半角英数字記号でご入力ください。",
    "33": "ユニークIDは50文字以内ででご入力ください。",
    "34": "所属",
    "35": "所属指定なし",
    "36": "メール受信設定",
    "37": "メール受信に同意いただける場合はチェックを入れてください。",
    "38": "言語",
    "39": "更新",
    "40": "キャンセル",
    "41": "クレジットカード情報の更新に成功しました",
    "42": "送信",
    "43": "支払い情報の変更",
    "44": "カード番号 末尾"
  },
  "NotFound": {
    "1": "指定されたページは見つかりませんでした。",
    "2": "マイページへ戻る"
  },
  "Plan": {
    "1": "現在のプラン：",
    "2": "サポートプラン",
    "3": "プランご利用期限：",
    "4": "プランのご変更は以下のフォームより受け付けております。",
    "5": "プランのご変更へ"
  },
  "Properties": {
    "1": "難易度",
    "2": "時間",
    "3": "コース"
  },
  "Question": {
    "1": "この試験はすでに終了しています。マイページに戻ります。",
    "2": "テストが開始されました",
    "3": "現在この試験に解答することはできません。マイページに戻ります。",
    "4": "テストが終了しました",
    "5": "この試験は現在解答することができません。マイページに戻ります。",
    "6": "残り時間",
    "7": "この試験はこのチームでは受験できません。マイページに戻ります。",
    "8": "受講ライセンスが付与されていません。マイページに戻ります。",
    "9": "この試験は存在しません。マイページに戻ります。",
    "10": "問題が発生しました。時間を空けて再度お試しください。",
    "11": "テスト",
    "12": "テストを終了する",
    "13": "終了する"
  },
  "QuestionBox": {
    "1": "問{{number}}"
  },
  "QuestionBoxModal": {
    "1": "質問箱",
    "2": "学習中に困ったこと、何でもご質問ください。",
    "3": "2営業日以内にお答えいたします。",
    "4": "ご質問内容",
    "5": "メールアドレスの形式が正しくありません",
    "6": "送信中...",
    "7": "送信する",
    "8": "こちらにご入力ください",
    "9": "返信用メールアドレス",
    "10": "ありがとうございます！",
    "11": "引き続き、Aidemyをよろしくお願いいたします。",
    "12": "送信が完了しました！",
    "13": "2営業日以内にお答えいたします。"
  },
  "QuestionnaireModal": {
    "1": "新規会員登録アンケート(約2分)",
    "2": "学生",
    "3": "社会人・その他"
  },
  "ReconfirmQuitTestModal": {
    "1": "未回答の問題、または「あとでもう一度チェックする」を付けた問題がありますが、本当にテストを終了しますか？",
    "2": "いいえ",
    "3": "はい"
  },
  "ResetPasswordModal": {
    "1": "登録しているメールアドレスを入力してください。",
    "2": "パスワード再設定",
    "3": "パスワード再設定のためのリンクを送信します。",
    "4": "送信中...",
    "5": "送信する",
    "6": "このメールアドレスのアカウントは存在しません",
    "7": "認証に失敗しました。時間を空けて再度お試しください。",
    "8": "メールを送信しました。メール本文のリンクから、再設定を完了させてください。",
    "9": "メールアドレスの形式が正しくありません。",
    "10": "登録メールアドレス"
  },
  "RouteCourseCard": {
    "1": "このコースを見る",
    "2": "コース"
  },
  "SearchedCoursesList": {
    "1": "検索条件に合致するコースが存在しませんでした",
    "2": "検索結果（{{number}}件）",
    "3": "（{{number}}件）",
    "4": "コース一覧"
  },
  "Tab": {
    "1": "標準",
    "2": "自動補完",
    "3": "配色テーマ",
    "4": "設定",
    "5": "キーバインド設定",
    "6": "オン",
    "7": "オフ",
    "8": "ライト",
    "9": "ダーク"
  },
  "UnactivatedExerciseModal": {
    "1": "受講ライセンスが必要です",
    "2": "受講を続ける場合は管理者にお問い合わせください。",
    "3": "コース詳細ページに戻る"
  },
  "UserQuestionnaireModal": {
    "1": "経営",
    "2": "営業/販売",
    "3": "製造/生産/品質管理",
    "4": "人事/総務/経理",
    "5": "その他",
    "6": "役員以上",
    "7": "部長クラス",
    "8": "課長クラス",
    "9": "主任係長クラス",
    "10": "一般社員",
    "11": "人文科学",
    "12": "社会科学",
    "13": "理学",
    "14": "工学",
    "15": "農学",
    "16": "医薬",
    "17": "家政",
    "18": "教育",
    "19": "なし",
    "20": "趣味/実務1年未満",
    "22": "初回登録時アンケート",
    "23": "今後のサービス品質向上のため、",
    "24": "アンケートにご協力ください。",
    "25": "誕生年",
    "26": "選択してください",
    "28": "役職",
    "29": "アイデミーでの学習目的",
    "30": "解決したいAI課題",
    "31": "学習時間 / 日",
    "32": "専攻分野",
    "33": "プログラミング経験年数",
    "34": "データサイエンス経験年数",
    "35": "経験のあるプログラミング言語",
    "36": "送信する",
    "37": "実務{{number}}年以上",
    "38": "趣味or実務1年未満",
    "39": "年",
    "40": "研究/開発",
    "41": "芸術",
    "42": "AIリテラシー向上のため",
    "43": "実装力向上のため",
    "44": "AIプロジェクト推進のため",
    "45": "企業の研修のため",
    "46": "試験のため",
    "47": "教養として",
    "48": "画像認識",
    "49": "異常検知",
    "50": "需要予測",
    "51": "言語処理",
    "52": "学習目的をご入力ください",
    "53": "専攻をご入力ください",
    "54": "経験のあるプログラミング言語をご入力ください",
    "55": "解決したいAI課題をご入力ください",
    "56": "その他",
    "57": "例）PHP, Swift, Objective-C",
    "58": "職種",
    "59": "選択してください",
    "60": "実務{{number}}年以上"
  },
  "UserQuestionnaireThanksModal": {
    "1": "引き続きサービス品質向上に取り組んでまいります。",
    "2": "Aidemyのご利用をよろしくお願いいたします。",
    "3": "ご協力ありがとうございました！",
    "4": "送信が完了しました！",
    "5": "画面の基本操作を見る"
  },
  "Video": {
    "1": "新動画プレイヤーを利用できません",
    "2": "新動画プレイヤーを利用できません。",
    "3": "チーム管理者様へお問い合わせください",
    "4": "ネットワーク設定をご確認ください",
    "5": "再生速度",
    "6": "{{speed}}倍速",
    "7": "標準",
    "8": "画質",
    "9": "自動",
    "10": "低画質",
    "11": "高画質",
    "12": "再生",
    "13": "一時停止",
    "14": "ピクチャー・イン・ピクチャー",
    "15": "フルスクリーン",
    "16": "フルスクリーン以外",
    "17": "ミュート",
    "18": "サウンドをオン",
    "19": "再生速度",
    "20": "字幕",
    "21": "オフ",
    "22": "キャプション",
    "23": "{{seconds}}秒進む",
    "24": "{{seconds}}秒戻る"
  },
  "Wantedly": {
    "1": "修了",
    "2": "Wantedlyに追加"
  },
  "__ArrowButtons": {
    "1": "前へ",
    "2": "次へ"
  },
  "coursesList": {
    "1": "新着順コース一覧",
    "2": "まずはここからスタート",
    "3": "ITエンジニアコース",
    "4": "ビジネスコース",
    "5": "ビジネス職向け",
    "6": "ITエンジニア向け",
    "7": "コード",
    "8": "動画",
    "9": "クイズ",
    "10": "3種総合",
    "11": "全て",
    "12": "AI アプリ開発講座",
    "13": "データ分析講座",
    "14": "自然言語処理講座",
    "15": "E資格対策講座",
    "16": "機械学習マスター講座",
    "17": "ビジネスAI活用講座",
    "18": "組織を変えるDX講座"
  },
  "curriculum": {
    "1": "AI アプリ開発講座",
    "2": "データ分析講座",
    "3": "自然言語処理講座",
    "4": "AI マーケティング講座",
    "5": "クラウド AI 開発講座",
    "6": "実践データサイエンス講座",
    "7": "E 資格対策講座",
    "8": "機械学習マスター講座",
    "9": "機械学習モデル開発講座",
    "10": "E 資格対策 + AI アプリ開発講座",
    "11": "E 資格対策 + データ分析講座",
    "12": "E 資格対策 + 自然言語処理講座",
    "13": "E資格対策 + AI マーケティング講座",
    "14": "アプリ開発講座"
  },
  "skipExerciseModal": {
    "1": "このエクササイズは完了していません。",
    "2": "このエクササイズをスキップしますか？",
    "3": "※スキップした場合は、完了ステータスになりません。完了した場合は、本ウィンドウを閉じ、「完了にして次の説明へ」または「この説明の演習問題へ」ボタンを押してください。",
    "4": "閉じる",
    "5": "スキップする"
  },
  "ResultBox": {
    "1": "問"
  },
  "UserSettingDropdown": {
    "1": "マイページ",
    "2": "コース一覧",
    "3": "アップグレード",
    "4": "管理者画面",
    "5": "スマートフォンからの管理画面の操作はサポートしていません",
    "6": "チューター用チームページ",
    "7": "アカウント設定",
    "8": "はじめての方へ",
    "9": "サポート",
    "10": "ログアウト",
    "11": "質問する",
    "12": "{{days}}営業日以内返信"
  },
  "ExerciseNavigation": {
    "1": "コース演習一覧",
    "2": "コースのトップページへ"
  },
  "ExerciseBlockQuiz": {
    "1": "選択問題"
  },
  "internetExplorerEnd": {
    "1": "Aidemyでは、Internet Explorerのサポートを 2021年12月31日 に終了しました。\nAidemyを快適にご利用いただけるよう、Chrome / Edge / Safari の最新版を推奨しております。\n\n※ 次回以降表示しない場合は、キャンセルを押下して下さい"
  },
  "ReleaseInfoModal": {
    "1": "完了",
    "2": "次へ",
    "3": "閉じる"
  },
  "Error": {
    "1": "エラーが発生しました。ページを再読み込みしてからお試しください。"
  }
}